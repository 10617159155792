import dayjs from 'dayjs';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import InfoItem from '../../Item/InfoItem';
import MeetupAppliedStatusTag from '../../Meetup/MeetupAppliedStatusTag';

import MeetupCardHeader from './MeetupCardHeader';
import { SIMPLE_ICON } from '@/constants/dict';
import { JR_EVENT_TYPE, JR_EVENT_TYPE_TEXT, MEETUP_TYPE } from '@/constants/meetup';
import { selectMeetupsLiveStatus } from '@/store/slices/socketSlice';
import { selectUserState } from '@/store/slices/userSlice';
import { boxBorder, boxHoverAnimation, textEllipsis } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import { formatDurationDHM } from '@/utils/date';
import { checkEventLiveStatus } from '@/utils/event';
import imageLoader from '@/utils/loader';
import getTimezone from '@/utils/timezone';

const Container = styled.div`
	border-radius: 1px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	gap: 10px;
	position: relative;
	width: '100%';
	@media ${devices.tablet} {
		background-color: #fff;
		border: solid 1px #000;
		flex-direction: column;
		${boxBorder('1px', '1px')}
		${boxHoverAnimation(4)}
		background-color: #fff;
		border: solid 1px #000;
		flex-direction: column;
		gap: 0;
	}
`;
const Header = styled.div`
	// 防止图片加载失败影响布局
	width: 166px;
	height: 96px;
	min-width: 166px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	@media ${devices.tablet} {
		height: 156px;
		width: 100%;
	}
`;
const HeaderImageWrapper = styled.div`
	height: 96px;
	position: absolute;
	width: 166px;

	@media ${devices.tablet} {
		width: 100%;
		height: 156px;
	}
`;

const HeaderOverlay = styled.div`
	background-color: rgba(0, 0, 0, 0.7);
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%; /* 黑色背景，50%透明度 */
`;
const HeaderSoldOut = styled.div`
	border: solid 3px ${color.primaryColor};
	color: ${color.primaryColor};
	font-size: 18px;
	font-weight: bold;
	padding: 0px 14px;
	transform: rotate(-20deg);
	z-index: 5;
	@media ${devices.tablet} {
		font-size: 38px;
	}
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 0;
	width: 100%;
	@media ${devices.tablet} {
		padding: 16px;
		padding-bottom: 14px;
		gap: 0;
	}
`;
const Title = styled.h3`
	font-size: 14px;
	${textEllipsis(2)}
	font-weight: 600;
	height: 36px;
	line-height: 18px;
	margin: 0;
	width: 100%;
	@media ${devices.tablet} {
		font-size: 18px;
		height: 48px;
		margin-bottom: 16px;
		line-height: 1.33;
	}
`;
const TitleTag = styled.p`
	background-color: #ffe400;
	border-radius: 1px;
	border-style: solid;
	border-width: 0.5px;
	display: inline;
	font-size: 10px;
	margin: 0;
	margin-right: 4px;
	padding: 2px 4px;
	width: fit-content;
	@media ${devices.tablet} {
		padding: 4px;
		display: none;
	}
`;
const Infos = styled.div`
	display: none;
	@media ${devices.tablet} {
		display: flex;
		flex-direction: column;
		min-height: 156px;
		width: 100%;
	}
`;
const InfosMobile = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	@media ${devices.tablet} {
		display: none;
	}
`;
const InfoItemMobile = styled.p`
	-webkit-line-clamp: 1;
	display: flex;
	font-size: 12px;
	height: 12px;
	justify-content: space-between;
	line-height: 1;
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
`;
const InfoItemDateMobileText = styled(InfoItemMobile)`
	display: block;
	height: auto;
`;
const BottomContainer = styled.div`
	display: none;
	@media ${devices.tablet} {
		display: unset;
		align-items: center;
		display: flex;
		height: 22px;
		justify-content: end;
		margin-top: 14px;
	}
`;
const End = styled.div`
	align-items: center;
	background-color: #eee;
	border-radius: 12px;
	color: #999;
	display: flex;
	padding: 4px 10px;
	width: fit-content;
`;
const SoldOutTag = styled.div`
	background-color: #ffd3d3;
	border-radius: 1px;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	padding: 4px 8px;
`;
const AlmostFullTag = styled.div`
	background-color: #fdecc6;
	border-radius: 1px;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	padding: 4px 8px;
`;
const AppliedMobileTag = styled.div`
	align-items: center;
	background-color: #2ebd53;
	border-bottom-left-radius: 50%;
	border-top-left-radius: 50%;
	bottom: 1px;
	color: #fff;
	display: flex;
	justify-content: center;
	padding: 3px 6px 3px 10px;
	position: absolute;
	right: 1px;
	p {
		font-size: 10px;
		line-height: 1;
		margin: 0;
	}
	@media ${devices.tablet} {
		display: none;
	}
`;
const EndMobileTag = styled.div`
	align-items: center;
	background-color: #eeeeee;
	border-bottom-left-radius: 50%;
	border-top-left-radius: 50%;
	bottom: 1px;
	color: #999999;
	display: flex;
	justify-content: center;
	padding: 3px 6px 3px 10px;
	position: absolute;
	right: 1px;
	p {
		font-size: 10px;
		line-height: 1;
		margin: 0;
	}
	@media ${devices.tablet} {
		display: none;
	}
`;
const MeetupHeaderMobileTag = styled(Image)`
	bottom: 1px;
	position: absolute;
	right: 1px;
	@media ${devices.tablet} {
		display: none;
	}
`;

interface MeetupCardProps {
	id: string;
	imageUrl: string;
	title: string;
	startTime: Date;
	endTime: Date;
	meetupType: MEETUP_TYPE;
	liveStreaming?: string;
	address?: string;
	tickets?: IMeetupTicket[];
	remainingNum: number;
	totalNum: number;
	applied?: boolean;
	jrEventType: JR_EVENT_TYPE;
	isLive: boolean;
	zoomMeetingId?: string;
}

const MeetupCard = ({
	id,
	imageUrl,
	title,
	startTime,
	endTime,
	meetupType,
	liveStreaming,
	address,
	tickets,
	remainingNum,
	totalNum,
	applied = false,
	jrEventType,
	isLive,
	zoomMeetingId
}: MeetupCardProps) => {
	const { city } = getTimezone();
	const user = useSelector(selectUserState);
	const meetupsLiveStatus = useSelector(selectMeetupsLiveStatus);
	const router = useRouter();
	const handleClick = () => {
		let path = `/events/${id}`;
		if (user.sales && user.sales.name) {
			path += `?source=${user.sales.name}`;
		}
		router.push(path);
	};

	const renderBottom = () => {
		// 优先级 已结束>已报名>活动状态
		if (dayjs(endTime).isBefore(dayjs())) {
			return (
				<BottomContainer>
					<End>已结束</End>
				</BottomContainer>
			);
		}
		if (!dayjs(endTime).isBefore(dayjs()) && applied) {
			return (
				<BottomContainer>
					<MeetupAppliedStatusTag status="applied" />
				</BottomContainer>
			);
		}
		return (
			<BottomContainer>
				{!remainingNum && <SoldOutTag>Sold out</SoldOutTag>}
				{!!remainingNum && remainingNum <= 10 && <AlmostFullTag>Almost full</AlmostFullTag>}
			</BottomContainer>
		);
	};

	const renderHeaderMobileTag = () => {
		if (dayjs(endTime).isBefore(dayjs())) {
			return (
				<EndMobileTag>
					<p>已结束</p>
				</EndMobileTag>
			);
		}
		if (!dayjs(endTime).isBefore(dayjs()) && applied) {
			return (
				<AppliedMobileTag>
					<p>已报名</p>
				</AppliedMobileTag>
			);
		}
		return (
			<MeetupHeaderMobileTag
				src={`/image/event/${meetupType}.svg`}
				alt=""
				width={54}
				height={18}
				loader={imageLoader}
				unoptimized
			/>
		);
	};

	return (
		<Container onClick={handleClick}>
			<MeetupCardHeader
				jrEventType={jrEventType as JR_EVENT_TYPE}
				startTime={startTime}
				isLive={checkEventLiveStatus(
					{
						zoomMeetingId,
						period: {
							start: startTime,
							end: endTime
						},
						isLive
					},
					meetupsLiveStatus[id]
				)}
			/>
			<Header>
				<HeaderImageWrapper>
					<Image
						src={imageUrl}
						alt={title}
						title={title}
						loader={imageLoader}
						fill
						sizes="100vw"
						style={{
							objectFit: 'cover'
						}}
					/>
				</HeaderImageWrapper>

				{!remainingNum && <HeaderOverlay />}
				{!remainingNum && <HeaderSoldOut>SOLD OUT</HeaderSoldOut>}
				{renderHeaderMobileTag()}
			</Header>
			<Content>
				<Title>
					{jrEventType && <TitleTag>{JR_EVENT_TYPE_TEXT[jrEventType]}</TitleTag>}
					{title}
				</Title>
				<Infos>
					<InfoItem icon={SIMPLE_ICON.CALENDAR} startTime={startTime} endTime={endTime} />
					<InfoItem
						icon={SIMPLE_ICON.LOCATION}
						meetupType={meetupType}
						liveStreaming={liveStreaming}
						address={address}
					/>
					<InfoItem icon={SIMPLE_ICON.TYPE} meetupType={meetupType as MEETUP_TYPE} />
					<InfoItem
						icon={SIMPLE_ICON.NOTE}
						meetupType={meetupType as MEETUP_TYPE}
						tickets={tickets}
					/>
					<InfoItem
						icon={SIMPLE_ICON.TICKET}
						remainingNum={remainingNum}
						totalNum={totalNum}
					/>
				</Infos>
				<InfosMobile>
					<InfoItemDateMobileText>
						{dayjs(startTime).format('YYYY/MM/DD HH:mm')}
						{city && ` (${city})`}
						&nbsp;
						<b
							style={{
								whiteSpace: 'nowrap'
							}}
						>{`${formatDurationDHM(dayjs(endTime).diff(startTime, 'second'))}`}</b>
					</InfoItemDateMobileText>
					<InfoItemMobile>
						{meetupType === MEETUP_TYPE.ONLINE ? liveStreaming : address}
					</InfoItemMobile>
					<InfoItemMobile>
						<InfoItem
							icon={SIMPLE_ICON.NOTE}
							meetupType={meetupType as MEETUP_TYPE}
							tickets={tickets}
						/>
						<InfoItem
							icon={SIMPLE_ICON.TICKET}
							remainingNum={remainingNum}
							totalNum={totalNum}
						/>
					</InfoItemMobile>
				</InfosMobile>
				{renderBottom()}
			</Content>
		</Container>
	);
};

export default MeetupCard;
