import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

export const getTimezone = () => {
	const timeZone = dayjs.tz.guess();
	const [country, city] = timeZone?.split('/') || [];
	return {
		timezone: timeZone,
		country,
		city
	};
};

export default getTimezone;
